import { Component, Watch } from 'vue-property-decorator'
import GtrModuleLayout from '@/modules/common/views/layouts/level-two/module/module.layout.vue'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import GtrChangelog from '@/modules/common/components/changelog/changelog.vue'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrEmailSettingsView',
  computed: {
    ...mapState('formbuilder', ['reportableEventFields']),
    ...mapState('languages', ['languages']),
    ...mapState('option', ['option_groups']),
    ...mapState('setting', ['email_settings', 'email_settingsFields', 'providerFields']),
    ...mapState('event', ['allContent', 'changelog', 'currentlyDeployedLiveUUID']),
    ...mapState('company', ['companyBlacklists']),
    ...mapState('contentpages', ['contentpages'])
  },
  components: {
    'gtr-changelog': GtrChangelog
  }
})

export default class GtrEmailSettingsView extends GtrSuper {
  data () {
    return {
      verification_code: '',
      prompt_for_code: false,
      from_email: '',
      from_name: '',
      submitting: false,
      loading: false,
      tab: null,
      items: ['General', 'Event', 'Login', 'Salesforce', 'Pardot', 'Marketo', 'Hubspot', 'Buttons', 'Onsite', 'Colors', 'Qualifiers', 'Barcode', 'Survey', 'Portal', 'Aditional'],
      languageToUse: 'en',
      settingsObject: {},
      registrationTypeKey: '_default',
      formVersion: 'default',
      settingsVersion: 'default',
      fieldsPrint: [],
      event_uuid: this.$route.params.event_uuid,
      onlyShowDefault: ['require_reg_type_before_registering', 'list_view_fields'],
      operatorFunctions: {
        '=': (a, b) => a === b,
        '!=': (a, b) => a !== b,
        '>': (a, b) => parseInt(a) > parseInt(b),
        '<': (a, b) => parseInt(a) < parseInt(b),
        '>=': (a, b) => parseInt(a) >= parseInt(b),
        '<=': (a, b) => parseInt(a) <= parseInt(b),
        '^=': (a, b) => a.includes(b)
      },
      valueType: [
        { text: 'Field', value: 'field' },
        { text: 'Literal', value: 'literal' }
      ],
      yesNo: [
        { text: 'No', value: false },
        { text: 'Yes', value: true }
      ],
      title: 'Default',
      providerDialog: false,
      _currentlyDeployedLiveUUID: null,
      _changelog: null,
      _currentlyDeployedDevUUID: null,
      openNewVerifiedSenderModal: null
    }
  }

  updated () {
    this.$emit('update:layout', GtrModuleLayout)
  }

  async mounted () {
    this.$store.commit('setting/SET_SETTINGS_LR', [])
    this.$store.commit('setting/SET_PROVIDER_FIELDS', [])
    this.$store.commit('setting/SET_SETTINGS_FIELDS_LR', {})
    this.$store.dispatch('common/showLoader', { value: true })
    await this.fetchLanguages()
    await this.fetchOptionGroups()
    await this.fetchSettings()
    await this.fetchSettingsFields()
    await this.fetchAllContent()
    await this.fetchChangelog()
    await this.fetchCurrentlyDeployedLiveUUID()
    await this.fetchCompanyBlacklists()
    await this.fetchContentpages()
    await this.fetchReportableEventFields()
    this.$store.dispatch('common/hideLoader')
  }

  @Watch('languages')
  onLanguagesChange (languages: any[]) {
    if (languages) {
      // this.$data.languageToUse = languages[0].name
      this.$data.languageToUse = 'en'
    }
  }

  @Watch('changelog')
  onChangeLogChange (payload: any) {
    if (payload) {
      this.$data._changelog = payload
    }
  }

  @Watch('settingsLoaded', { deep: true })
  onSettingsLoadedChange (newVal: any) {
    if (newVal) {
      const settingsObject = {}
      const settingsFields = this.$store.state.setting.email_settingsFields
      const languages = this.$store.state.languages.languages
      for (const category in settingsFields) {
        settingsObject[category] = {}
        for (const setting in settingsFields[category]) {
          settingsObject[category][setting] = {}
          settingsObject[category][setting].name = setting
          /**
           * Add type to the setting
           */
          settingsObject[category][setting].name = setting
          settingsObject[category][setting].show_field = true
          settingsObject[category][setting].type = settingsFields[category][setting].type
          settingsObject[category][setting].description = settingsFields[category][setting].description
          settingsObject[category][setting].display_name = settingsFields[category][
            setting
          ].display_name
          settingsObject[category][setting].provider = settingsFields[category][setting].provider
          settingsObject[category][setting].value = {}
          settingsObject[category][setting].temp = null
          if (settingsFields[category][setting].show_if) {
            settingsObject[category][setting].show_if = settingsFields[category][setting].show_if
          }

          if (settingsFields[category][setting].type === 'select') {
            settingsObject[category][setting].selectItems = settingsFields[category][setting].options
          }
          for (let i = 0; i < this.registration_types.length; i++) {
            /**
             * Only add the _default registration type to settings that are not registration-type specific
             */
            if (this.$data.onlyShowDefault.includes(setting) && this.registration_types[i].value !== '_default') {
              continue
            }
            const regTypeUUID = this.registration_types[i].value // function scope for the below statement
            const settingType = settingsFields[category][setting].type
            if (
              settingType === 'localized' ||
              settingType === 'localized_html' ||
              settingType === 'field' ||
              settingType === 'fields' ||
              settingType === 'fieldmapping'
            ) {
              settingsObject[category][setting].value[this.registration_types[i].value] = {}

              if (settingType === 'localized' || settingType === 'localized_html') {
                for (let i = 0; i < languages.length; i++) {
                  if (!settingsObject[category][setting].value[regTypeUUID][languages[i].name]) {
                    settingsObject[category][setting].value[regTypeUUID][languages[i].name] = ''
                  }
                }
              }

              if (settingsFields[category][setting].type === 'field') {
                settingsObject[category][setting].value[this.registration_types[i].value].label = {}
                settingsObject[category][setting].value[this.registration_types[i].value].field = ''
                settingsObject[category][setting].error = ''

                for (let i = 0; i < languages.length; i++) {
                  if (!settingsObject[category][setting].value[regTypeUUID].label[languages[i].name]) {
                    settingsObject[category][setting].value[regTypeUUID].label[languages[i].name] = ''
                  }
                }
              }

              if (settingsFields[category][setting].type === 'fields') {
                settingsObject[category][setting].error = ''
              }
              if (
                settingsFields[category][setting].type === 'fields' ||
                settingsFields[category][setting].type === 'fieldmapping'
              ) {
                settingsObject[category][setting].value[this.registration_types[i].value] =
                  typeof settingsFields[category][setting].default !== 'undefined'
                    ? settingsFields[category][setting].default
                    : []
              }

              if (typeof settingsFields[category][setting].default === 'object') {
                settingsObject[category][setting].value[this.registration_types[i].value] = settingsFields[category][setting].default
              }
            } else {
              settingsObject[category][setting].value[this.registration_types[i].value] =
                typeof settingsFields[category][setting].default !== 'undefined'
                  ? settingsFields[category][setting].default
                  : ''
              if (settingType === 'yn') {
                settingsObject[category][setting].temp = settingsFields[category][setting].default
              }
            }

            /// ///////

            /* if (
              settingsFields[category][setting].type === 'localized' ||
              settingsFields[category][setting].type === 'localized_html'
            ) {
              settingsObject[category][setting].value[this.registration_types[i].value] = {}

              if (typeof settingsFields[category][setting].default === 'object') {
                settingsObject[category][setting].value[this.registration_types[i].value] = settingsFields[category][setting].default
              }
              const languages = this.$store.state.languages.languages
              for (let i = 0; i < languages.length; i++) {
                if (!settingsObject[category][setting].value[regTypeUUID][languages[i].name]) {
                  settingsObject[category][setting].value[regTypeUUID][languages[i].name] = ''
                }
              }
            } else if (settingsFields[category][setting].type === 'field') {
              settingsObject[category][setting].value[this.registration_types[i].value] = {}
              settingsObject[category][setting].value[this.registration_types[i].value].label = {}
              settingsObject[category][setting].value[this.registration_types[i].value].field = ''

              if (typeof settingsFields[category][setting].default !== 'undefined') {
                settingsObject[category][setting].value[this.registration_types[i].value] = settingsFields[category][setting].default
              }

              const languages = this.$store.state.languages.languages
              for (let i = 0; i < languages.length; i++) {
                if (!settingsObject[category][setting].value[regTypeUUID].label[languages[i].name]) {
                  settingsObject[category][setting].value[regTypeUUID].label[languages[i].name] = ''
                }
              }
            } else if (
              settingsFields[category][setting].type === 'fields' ||
              settingsFields[category][setting].type === 'fieldmapping'
            ) {
              settingsObject[category][setting].value[this.registration_types[i].value] =
                typeof settingsFields[category][setting].default !== 'undefined'
                  ? settingsFields[category][setting].default
                  : []
            } else {
              settingsObject[category][setting].value[this.registration_types[i].value] =
                typeof settingsFields[category][setting].default !== 'undefined'
                  ? settingsFields[category][setting].default
                  : ''
            } */
          }
        }
      }
      /**
       * Set value from this.settings
       */
      const settings = this.$store.state.setting.email_settings
      if (settings.page_data) {
        for (const setting in settings.page_data) {
          for (const category in settingsObject) {
            if (settingsObject[category][setting]) {
              if (settingsObject[category][setting].type === 'datetime') {
                settingsObject[category][setting].value = {}
                for (const reg_type in this.registration_types) {
                  if (settings.page_data[setting][this.registration_types[reg_type].value]) {
                    settingsObject[category][setting].value[
                      this.registration_types[reg_type].value
                    ] = this.convertDatetimeToLocalTimezone(
                      settings.page_data[setting][this.registration_types[reg_type].value]
                    )
                  } else {
                    settingsObject[category][setting].value[this.registration_types[reg_type].value] = null
                  }
                }
              } else {
                // settingsObject[setting].value = settings.page_data[setting]
                for (const value in settings.page_data[setting]) {
                  settingsObject[category][setting].value[value] = settings.page_data[setting][value]
                  if (settingsObject[category][setting].type === 'fields') {
                    settingsObject[category][setting].temp_field = null
                    settingsObject[category][setting].temp_label = null
                  } else if (settingsObject[category][setting].type === 'fieldmapping') {
                    settingsObject[category][setting].temp_field = null
                    settingsObject[category][setting].temp_external_field = null
                    settingsObject[category][setting].temp_value_type = null
                  } else if (settingsObject[category][setting].type === 'yn') {
                    settingsObject[category][setting].temp = settings.page_data[setting]._default
                  }
                }
              }
            }
          }

          /* if (!settingsObject.hasOwnProperty(setting)) {
            continue
          }
          if (settingsObject[setting].type === 'datetime') {
            settingsObject[setting].value = {}
            for (const reg_type in this.registration_types) {
              if (settings.page_data[setting][this.registration_types[reg_type].value]) {
                settingsObject[setting].value[
                  this.registration_types[reg_type].value
                ] = this.convertDatetimeToLocalTimezone(
                  settings.page_data[setting][this.registration_types[reg_type].value]
                )
              } else {
                settingsObject[setting].value[this.registration_types[reg_type].value] = null
              }
            }
          } else {
            // settingsObject[setting].value = settings.page_data[setting]
            for (const value in settings.page_data[setting]) {
              settingsObject[setting].value[value] = settings.page_data[setting][value]
              if (settingsObject[setting].type === 'fields' || settingsObject[setting].type === 'fieldmapping') {
                settingsObject[setting].temp_field = null
                settingsObject[setting].temp_label = null
              }
            }
          } */
        }
      }
      this.$data.settingsObject = settingsObject
      /**
       * Run show if checked now that the values are loaded in the settingsObject
       */
      this.showOrHideSettingsFields()
    }
  }

  @Watch('providerFields')
  onProviderFieldsChange () {
    this.$data.providerDialog = true
  }

  @Watch('reportableEventFields')
  onReportableEventFieldsChange (newVal) {
    for (const fieldIndex in newVal.participants) {
      const field = newVal.participants[fieldIndex]
      this.$data.fieldsPrint.push({ text: field.label, value: field.field })
    }
    for (const fieldIndex in newVal.option_groups) {
      const field = newVal.option_groups[fieldIndex]
      this.$data.fieldsPrint.push({ text: 'Option Group: ' + field.label, value: field.field })
    }
  }

  @Watch('settingsVersion')
  async onSettingsVersionChange (settings_uuid: string): Promise<void> {
    if (settings_uuid !== 'default') {
      const response = await this.$store.dispatch('setting/getSettingsByUuid', {
        event_uuid: this.$data.event_uuid,
        settings_uuid
      })
      if (response) {
        const settings = response.data.page_data
        for (const setting in settings) {
          if (this.$data.settingsObject['GTR Leads'][setting]) {
            this.$data.settingsObject['GTR Leads'][setting].value = settings[setting]
          }
        }
        this.$data._currentlyDeployedDevUUID = settings_uuid
      }
      Container.get(Notification).success('Settings version successfully changed.')
    }
  }

  @Watch('currentlyDeployedLiveUUID')
  onCurrentlyDeployedLiveUUIDChange (payload: any) {
    if (payload && payload.live) {
      this.$data._currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  get languageItems () {
    const languages = this.$store.state.languages.languages
    if (!Object.keys(languages)) {
      return []
    }
    const arr: any = []
    for (let i = 0; i < languages.length; i++) {
      arr.push({
        text: languages[i].display,
        value: languages[i].name
      })
    }
    return arr
  }

  get registration_types () {
    const option_groups = this.$store.state.option.option_groups
    if (Object.keys(option_groups).length === 0) {
      return []
    }
    const registration_types_array = option_groups
      .filter(group => group.name === 'Registration Types')
      .shift().options
    const registration_types: any = []
    for (let i = 0; i < registration_types_array.length; i++) {
      registration_types.push({
        text: registration_types_array[i].name,
        value: registration_types_array[i].uuid
      })
    }
    registration_types.push({
      text: 'Default',
      value: '_default'
    })
    return registration_types
  }

  registrationTypesToDisplay (setting) {
    if (this.$data.onlyShowDefault.includes(setting)) {
      return this.registration_types.filter(registration_type => registration_type.value === '_default')
    }
    return this.registration_types
  }

  registrationTypesValuesToDisplay (setting, valueObject) {
    if (this.$data.onlyShowDefault.includes(setting)) {
      for (const registration_type in valueObject) {
        if (registration_type !== '_default') {
          delete valueObject[registration_type]
        }
      }
    }
    return valueObject
  }

  get registrationTypeItems () {
    const languages = this.$store.state.languages.languages
    if (!Object.keys(languages)) {
      return []
    }
    return this.registration_types
  }

  get currentlyDeployedDevUUID () {
    /**
     * We can assume that the first index in the changelog data array is the most recent dev deploy
     *
     * Doing this saves us from making another api call
     */
    const changelog = this.$store.state.event.changelog
    if (!changelog.data || changelog.data.length === 0) {
      return {}
    }
    return changelog.data[0].uuid
  }

  get settingsLoaded () {
    const settingsFields = this.$store.state.setting.email_settingsFields
    const languages = this.$store.state.languages.languages
    const settings = this.$store.state.setting.email_settings
    this.$data._currentlyDeployedDevUUID = settings.uuid
    return (
      Object.keys(settingsFields).length > 0 &&
      Object.keys(languages).length > 0 &&
      this.registration_types.length > 0 &&
      settings !== false
    )
  }

  get blacklists () {
    const arr: any = []
    const companyBlacklists = this.$store.state.company.companyBlacklists
    companyBlacklists.forEach(blacklist => {
      arr.push({ text: blacklist.name, value: blacklist.uuid })
    })
    return arr
  }

  get pages () {
    const arr: any = []
    const contentpages = this.$store.state.contentpages.contentpages
    for (const page in contentpages) {
      arr.push({
        text: contentpages[page].display_name,
        value: page
      })
    }
    return arr
  }

  showOrHideSettingsFields () {
    const settings_with_show_if: any = []
    for (const setting in this.$data.settingsObject) {
      if (this.$data.settingsObject[setting].hasOwnProperty('show_if')) {
        settings_with_show_if.push(this.$data.settingsObject[setting])
      }
    }
    for (let i = 0; i < settings_with_show_if.length; i++) {
      const setting_name = settings_with_show_if[i].name
      const setting_show_if = settings_with_show_if[i].show_if
      /**
       * Get the field and the current value of the setting
       */
      const show_if_field = setting_show_if.field
      const show_if_operator = setting_show_if.operator
      const show_if_value = setting_show_if.value

      if (
        this.$data.settingsObject[show_if_field].type === 'localized' ||
        this.$data.settingsObject[show_if_field].type === 'localized_html'
      ) {
        if (
          this.$data.operatorFunctions[show_if_operator](
            this.$data.settingsObject[show_if_field].value[this.$data.registrationTypeKey][this.$data.languageToUse],
            show_if_value
          )
        ) {
          this.$data.settingsObject[setting_name].show_field = true
        } else {
          this.$data.settingsObject[setting_name].show_field = false
        }
      } else {
        if (
          this.$data.operatorFunctions[show_if_operator](
            this.$data.settingsObject[show_if_field].value[this.$data.registrationTypeKey],
            show_if_value
          )
        ) {
          this.$data.settingsObject[setting_name].show_field = true
        } else {
          this.$data.settingsObject[setting_name].show_field = false
        }
      }
    }
  }

  convertSettingValuesToItemsArray (obj) {
    const arr: any = []
    for (const key in obj) {
      arr.push({
        text: obj[key],
        value: key
      })
    }
    return arr
  }

  updateSettingsVersion (uuid) {
    this.$data.settingsVersion = uuid
  }

  addNewPricingTier () {
    // TO DO
  }

  addField (setting) {
    const fieldData = {
      field: '',
      label: {}
    }
    const languages = this.$store.state.languages.languages
    for (let i = 0; i < languages.length; i++) {
      fieldData.label[languages[i].name] = ''
    }
    setting.value[this.$data.registrationTypeKey].push(fieldData)
  }

  addExternalField (setting) {
    const fieldData = {
      field: '',
      label: {}
    }
    const languages = this.$store.state.languages.languages
    for (let i = 0; i < languages.length; i++) {
      fieldData.label[languages[i].name] = ''
    }
    setting.value[this.$data.registrationTypeKey].push(fieldData)
  }

  getUsedSettings (items, settingsUsed) {
    const newItems = {}
    for (const ind in items) {
      if (settingsUsed.indexOf(ind) !== -1) {
        newItems[ind] = items[ind]
      }
    }
    return newItems
  }

  getSettingName (setting, key) {
    return setting.display_name ? setting.display_name : key
  }

  async loadFields (provider, table) {
    this.$store.dispatch('setting/getProviderFields', { event_uuid: this.$route.params.event_uuid, provider, table })
  }

  private async fetchLanguages () {
    try {
      await this.$store.dispatch('languages/fetchLanguages', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchOptionGroups () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSettings () {
    try {
      await this.$store.dispatch('setting/getSettingsEmail', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        this.$store.commit('setting/SET_SETTINGS_EMAIL', [])
        Container.get(ErrorHandlerService).error(error)
      }
    }
  }

  private async fetchSettingsFields () {
    try {
      await this.$store.dispatch('setting/getSettingsFieldsEmail')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchAllContent () {
    try {
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchChangelog () {
    try {
      const changelogData = {
        type: 'email_settings',
        event_uuid: this.$route.params.event_uuid,
        subtype: 'default/all?no_data=true'
      }
      await this.$store.dispatch('event/getChangelog', changelogData)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchCurrentlyDeployedLiveUUID () {
    try {
      const deployLiveData = {
        type: 'email_settings',
        event_uuid: this.$route.params.event_uuid,
        sub_type: 'default'
      }
      await this.$store.dispatch('event/getCurrentlyDeployedLiveUUID', deployLiveData)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchCompanyBlacklists () {
    try {
      await this.$store.dispatch('company/getCompanyBlacklists', { company_uuid: this.$route.params.uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchContentpages () {
    try {
      const { event_uuid } = this.$route.params
      await this.$store.dispatch('contentpages/getContentPages', { event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchReportableEventFields () {
    try {
      await this.$store.dispatch('formbuilder/getReportableEventFields', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async saveSettings () {
    try {
      this.$data.submitting = true
      const settingsData: any = {
        event_uuid: this.$route.params.event_uuid
      }
      const _settingsObject = {}
      for (const category in this.$data.settingsObject) {
        Object.assign(_settingsObject, this.$data.settingsObject[category])
      }
      for (const setting in _settingsObject) {
        if (_settingsObject[setting].type === 'datetime') {
          settingsData[setting] = {}
          for (const registrationType in _settingsObject[setting].value) {
            if (_settingsObject[setting].value[registrationType]) {
              settingsData[setting][registrationType] = this.convertDatetimeToUTC(
                _settingsObject[setting].value[registrationType]
              )
            } else {
              settingsData[setting][registrationType] = _settingsObject[setting].value[
                registrationType
              ]
            }
          }
        } else if (_settingsObject[setting].type === 'url') { // if the type is a url.
          const temp = _settingsObject[setting].value // store the value of the setting.
          // setup a loop.
          let j = 0
          let count = Object.keys(temp).length

          // while j is less then count.
          while (j < count) {
            if (Object.keys(temp)[j].includes('temp')) { // if it has the word temp, delete it.
              delete temp[Object.keys(temp)[j]]
              count-- // decrement count.
            }
            j++ // increment j.
          }
          delete temp.temp_file
          settingsData[setting] = temp
        } else {
          settingsData[setting] = _settingsObject[setting].value
        }
      }

      const from_email = settingsData.email_from_address._default
      const from_name = settingsData.email_from_name._default
      try {
        const response = await this.$store.dispatch('email/checkIfSenderVerified', from_email)
        if (response.verified) {
          await this.$store.dispatch('setting/saveSettingsEmail', {
            event_uuid: this.$route.params.event_uuid,
            data: settingsData
          })
          Container.get(Notification).success('Settings successfully saved.')
        } else {
          this.$data.from_email = from_email
          this.$data.from_name = from_name
          this.$data.openNewVerifiedSenderModal = true
        }
      } catch (error) {
        this.$data.from_email = from_email
        this.$data.from_name = from_name
        this.$data.openNewVerifiedSenderModal = true
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
    }
  }

  public async submitVerifiedSender () {
    try {
      await this.$store.dispatch('email/submitVerifiedSender', { from_email: this.$data.from_email, from_name: this.$data.from_name })
      this.$data.prompt_for_code = true
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  public async submitCode () {
    try {
      const response = await this.$store.dispatch('email/verifySender', this.$data.verification_code)
      Container.get(Notification).success('Sender verified')
      this.$data.openNewVerifiedSenderModal = false
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  setRegTypeValue (setting: any, reg_type: any, tab: any) {
    if (setting.type === 'localized' || setting.type === 'localized_html') {
      if (setting.temp !== null) {
        const values = this.$data.settingsObject[tab][setting.name].value[reg_type.value]
        this.$data.settingsObject[tab][setting.name].value[reg_type.value] = {
          [this.$data.languageToUse]: setting.temp
        }
      }
    } else if (setting.type === 'fields') {
      if (setting.temp_field && setting.temp_label) {
        setting.error = ''
        const reg_type_values = this.$data.settingsObject[tab][setting.name].value[reg_type.value]
        const temp: any = []
        reg_type_values.forEach(value => {
          if (value.field !== setting.temp_field) {
            temp.push(value)
          }
        })
        temp.push({ type: 'field', field: setting.temp_field, label: { en: setting.temp_label } })
        this.$data.settingsObject[tab][setting.name].value[reg_type.value] = temp
      } else {
        setting.error = 'Both field and label are required.'
      }
    } else if (setting.type === 'fieldmapping') {
      if (setting.temp_field && setting.temp_external_field && setting.temp_value_type) {
        const reg_type_values = this.$data.settingsObject[tab][setting.name].value[reg_type.value]
        const temp: any = []
        reg_type_values.forEach(value => {
          if (value.field !== setting.temp_field) {
            temp.push(value)
          }
        })
        temp.push({ external_field: setting.temp_external_field, field: setting.temp_field, valueType: setting.temp_value_type })
        this.$data.settingsObject[tab][setting.name].value[reg_type.value] = temp
      }
    } if (setting.type === 'url') {
      if (setting.temp !== null) {
        this.$data.settingsObject[tab][setting.name].value[this.parseSetting(reg_type.text) + '_temp_file'] = setting.temp
        this.setImageBase64(setting.temp, reg_type.value, setting, tab)
      }
    } else {
      if (setting.temp !== null && setting.type !== 'localized_html') {
        this.$data.settingsObject[tab][setting.name].value[reg_type.value] = setting.temp
      }
    }
  }

  removeField (setting: any, reg_type: any, field: any, tab: any) {
    this.$data.settingsObject[tab][setting.name].value[reg_type] = this.$data.settingsObject[tab][setting.name].value[reg_type].filter(value => {
      if (value.field !== field.field) {
        return true
      }
    })
  }

  capitalizeText (value: string) {
    return value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
  }

  parseSetting (value: any) {
    return value.split(' ').join('_').toLowerCase()
  }

  regTypeName (value: string) {
    const _value: any = this.registration_types.filter((reg_type: any) => reg_type.value === value)
    if (_value.length) {
      return this.capitalizeText(_value[0].text)
    }
  }

  regTypeValue (name: string) {
    const _value: any = this.registration_types.filter((reg_type: any) => reg_type.text === name)
    if (_value.length) {
      return _value[0].value
    }
  }

  getFileName (file: File) {
    if (file) {
      return file.name
    }
  }

  getRegTypeValues (values: any) {
    const regValues: any = {}
    for (const value in values) {
      if (values[value] && values[value] instanceof File === false) {
        regValues[value] = values[value]
      }
    }
    return regValues
  }

  setImageBase64 (file: File, reg_type_value: any, setting: any, tab: any) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.$data.settingsObject[tab][setting.name].value[reg_type_value] = reader.result
    }
  }

  async reUseValue (setting: any, value: any) {
    if (setting.type === 'localized_html' || setting.type === 'localized') {
      setting.temp = value[this.$data.languageToUse]
    } else if (setting.type === 'fields' || setting.type === 'fieldmapping') {
      setting.temp_field = value.field
      setting.temp_label = value.label[this.$data.languageToUse]
    } else if (setting.type === 'url') {
      setting.temp = await this.convertUrlToFile(value)
    } else {
      setting.temp = value
    }
  }

  private async convertUrlToFile (url: string) {
    const response = await fetch(url)
    const blob = await response.blob()
    const fileExtension = url.split('.').pop()
    const file = new File([blob], 'image.' + fileExtension, { type: blob.type })
    return file
  }
}
