import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { GtrEmailTrigger } from 'gtr-types'
import GtrEmailTriggerEditView from './edit/edit.view.vue'

@Component({
  name: 'GtrEmailTriggersView',
  computed: {
    ...mapState('module', ['activatedEventModules']),
    ...mapState('email', ['emailTriggers'])
  },
  components: {
    'gtr-email-trigger-edit': GtrEmailTriggerEditView
  }
})
export default class GtrEmailTriggersView extends GtrSuper {
  emailTriggers!: GtrEmailTrigger[]

  activatedEventModules!: Record<string, any>;

  data () {
    return {
      loading: false,
      editModalOpen: false,
      triggerToEdit: null
    }
  }

  async mounted () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('email/fetchEmailTriggers', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  openEditModal (trigger: GtrEmailTrigger) {
    this.$data.editModalOpen = true
    this.$data.triggerToEdit = trigger
  }

  handleCloseEditModal () {
    this.$data.editModalOpen = false
    this.$data.triggerToEdit = null
  }
}
