import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrEmailTriggersView from './triggers/triggers.view.vue'
import GtrEmailSettingsView from './settings/settings.view.vue'

@Component({
  name: 'GtrEventEmailsView',
  computed: {
    ...mapState('setting', ['settings']),
    ...mapState('email', ['emails', 'emails_live', 'scheduled_emails', 'sent_scheduled_emails']),
    ...mapState('option', ['option_groups']),
    ...mapState('module', ['activatedEventModules']),
    ...mapState('sessions', ['sessions'])
  },
  components: {
    'gtr-email-triggers-view': GtrEmailTriggersView,
    'gtr-email-settings-view': GtrEmailSettingsView
  }
})
export default class GtrEventEmailsView extends GtrSuper {
  settings!: Record<string, any>

  emails!: Record<string, any>

  scheduled_emails!: Record<string, any>

  sent_scheduled_emails!: Record<string, any>

  option_groups!: Record<string, any>

  sessions!: Array<Record<string, any>>

  data () {
    return {
      loading: false,
      activeTab: 0,
      scheduledEmailToDelete: null,
      comparisonOperators: {
        '=': 'is',
        '!=': 'is not',
        '>': 'is greater than',
        '<': 'is less than',
        '>=': 'is greater than or equal to',
        '<=': 'is less than or equal to',
        '^=': 'contains',
        '=null': 'is null',
        '!=null': 'is not null'
      },
      scheduledEmailSearch: '',
      scheduledEmailTableHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: true
        },
        {
          text: 'Send Time',
          value: 'send_time',
          sortable: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '128px'
        }
      ],
      emailSearch: '',
      emailTableHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: true
        },
        {
          text: 'Category',
          value: 'category',
          sortable: true
        },
        {
          text: 'Subject',
          value: 'subject',
          sortable: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '64px'
        }
      ],
      sentEmailTable: {
        headers: [
          {
            text: 'Sent',
            value: 'date',
            sortable: true
          },
          {
            text: 'Name',
            value: 'name',
            sortable: true
          },
          {
            text: 'Criteria',
            value: 'criteria',
            sortable: false
          },
          {
            text: '',
            value: 'participants_emailed_btn',
            sortable: false
          }
        ],
        search: ''
      }
    }
  }

  created () {
    this.registerEvents()
  }

  async mounted () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
      await this.fetchSettings()
      await this.fetchEmails()
      await this.fetchSessions()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get usableEmails (): any[] {
    const { LEADS: { enabled: leadsEnabled } = { enabled: false } } = (this as any).activatedEventModules || {}
    const { REGISTRATION: { enabled: regEnabled } = { enabled: false } } = (this as any).activatedEventModules || {}
    let participantPasswordsEnabled = false
    for (const regType in this.settings?.page_data?.auth_field) {
      if (this.settings.page_data.auth_field[regType].field === 'password') { // if any reg types use passwords
        participantPasswordsEnabled = true
        break
      }
    }
    const filteredEmails = (this.emails || []).filter(email => {
      const disabledEmails = ['admin_registration_notification', 'admin_cancellation_notification']
      if (!regEnabled) {
        disabledEmails.push('approved', 'declined', 'pending', 'login_details')
      }
      if (!leadsEnabled) {
        disabledEmails.push('lrorders_confirmation')
      }
      if (!participantPasswordsEnabled) {
        disabledEmails.push('login_forgot_password')
      }
      return !disabledEmails.includes(email.key)
    })
    return filteredEmails.sort((a, b) => a?.value?.page_name > b?.value?.page_name ? 1 : -1)
  }

  get emailTableItems (): any[] {
    return this.usableEmails.map(usableEmail => {
      const { page_data, uuid } = usableEmail.value
      const { name, category, subject: subjectRaw } = page_data[0].email_data
      const subject = subjectRaw?.en ? subjectRaw.en : subjectRaw
      return {
        uuid,
        category,
        name: this.handleEmailName(name),
        subject
      }
    })
  }

  get scheduledEmailsTableItems (): any[] {
    return this.scheduledEmails.map(scheduledEmail => {
      const { uuid, email_name: name, send_time } = scheduledEmail.value
      return {
        uuid,
        name: this.handleEmailName(name),
        send_time: this.convertFromUtcToEventTimezone(send_time)
      }
    })
  }

  get scheduledEmails (): any[] {
    const emails = JSON.parse(JSON.stringify(this.scheduled_emails))
    return emails.sort((a, b) => a.value.send_time > b.value.send_time ? 1 : -1)
  }

  get sentScheduledEmails (): any[] {
    const emails: any[] = []
    this.sent_scheduled_emails.forEach(email => {
      emails.push({
        date: this.$options.filters?.formatDateLocal(email.value.sent_time, ''),
        name: this.handleEmailName(email.value.email_name),
        criteria: this.prettyPrintCriteria(email.value.criteria),
        uuid: email.value.uuid
      })
    })
    return emails.sort((a, b) => a.date < b.date ? 1 : -1)
  }

  getOptionGroupName (uuid: string): string {
    for (let i = 0; i < this.option_groups.length; i++) {
      if (this.option_groups[i].uuid === uuid) {
        return this.option_groups[i].name
      }
    }
    return ''
  }

  getOptionGroupValueName (groupUuid: string, optionUuid: string): string {
    for (let i = 0; i < this.option_groups.length; i++) {
      const optionGroup = this.option_groups[i]
      if (optionGroup.uuid === groupUuid) {
        for (let j = 0; j < optionGroup.options.length; j++) {
          const option = optionGroup.options[j]
          if (option.uuid === optionUuid) {
            return option.name
          }
        }
      }
    }
    return ''
  }

  formatFieldName (fieldName: string): string {
    return '<span class="gtr-text-capitalize">' + fieldName.replace(/_/g, ' ') + '</span>'
  }

  prettyPrintCriteria (criteria: any): string {
    switch (criteria.type) {
      case 'field_criteria':
        return this.formatFieldName(criteria.field) + ' ' + this.$data.comparisonOperators[criteria.operator] + ' ' + (!['=null', '!=null'].includes(criteria.operator) ? criteria.value : '')
      case 'option_criteria': {
        return this.getOptionGroupName(criteria.field) + ' ' + this.$data.comparisonOperators[criteria.operator] + ' ' + this.getOptionGroupValueName(criteria.field, criteria.value)
      }
      case 'session_criteria': {
        return `${this.formatFieldName(criteria.operator)} ${this.getSessionName(criteria.value)}`
      }
      case 'grouping': {
        const groups: string[] = []
        for (let i = 0; i < criteria.group_items.length; i++) { // loop through groups
          const group = criteria.group_items[i]
          const groupItems: string[] = []
          if (group.group_items) {
            for (let j = 0; j < group.group_items.length; j++) { // loop through group items
              const groupItem = group.group_items[j]
              const { fieldName, fieldValue } = this.processField(groupItem)
              groupItems.push(fieldName + ' ' + this.$data.comparisonOperators[groupItem.operator] + ' ' + fieldValue)
            }
          } else {
            const groupItem = group
            const { fieldName, fieldValue } = this.processField(groupItem)
            groupItems.push(fieldName + ' ' + this.$data.comparisonOperators[groupItem.operator] + ' ' + fieldValue)
          }
          groups.push(groupItems.join(`<div class="criteriaOperator">${group.group_operator}</div>`))
        }
        return groups.join(`<div class="criteriaGroupOperator">${criteria.group_operator}</div>`)
      }
      default:
        return '*'
    }
  }

  private processField (groupItem: any) {
    let fieldName = ''
    let fieldValue = ''
    if (groupItem.type === 'option_criteria') {
      fieldName = this.getOptionGroupName(groupItem.field)
      if (!fieldName) {
        fieldName = groupItem.field
      }
      fieldValue = this.getOptionGroupValueName(groupItem.field, groupItem.value)
      if (!fieldValue) {
        fieldValue = groupItem.value
      }
    } else {
      fieldName = this.formatFieldName(groupItem.field)
      if (!['=null', '!=null'].includes(groupItem.operator)) {
        fieldValue = groupItem.value
      }
    }
    return { fieldName, fieldValue }
  }

  private getSessionName (uuid: string): string {
    return this.sessions.find(session => session.uuid === uuid)?.name || ''
  }

  onRemoveScheduledEmail (email: any) {
    this.$data.scheduledEmailToDelete = email
  }

  async removeScheduledEmail (payload: any) {
    if (payload.confirm) {
      try {
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          scheduled_uuid: this.$data.scheduledEmailToDelete.uuid
        }
        await this.$store.dispatch('email/removeScheduledEmail', payload)
        Container.get(Notification).success('Email successfully deleted.')
        await this.fetchEmails()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
    this.$data.scheduledEmailToDelete = null
  }

  private registerEvents () {
    this.$bus.$on('gtr.email_created', this.fetchEmails)
    this.$bus.$on('gtr.email_updated', this.fetchEmails)
  }

  private async fetchSettings () {
    try {
      await this.$store.dispatch('setting/getSettings', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        this.$store.commit('setting/SET_SETTINGS', [])
        Container.get(ErrorHandlerService).error(error)
      }
    }
  }

  private async fetchEmails () {
    this.$store.commit('email/CLEAR_EMAILS')
    this.$store.commit('email/CLEAR_EMAILS_LIVE')
    this.$store.commit('email/CLEAR_SCHEDULED_EMAILS')
    this.$store.commit('email/CLEAR_SENT_SCHEDULED_EMAILS')
    await this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid)
    await this.$store.dispatch('email/fetchEmailsLive', this.$route.params.event_uuid)
    await this.$store.dispatch('email/fetchScheduledEmails', this.$route.params.event_uuid)
    await this.$store.dispatch('email/fetchSentScheduledEmails', this.$route.params.event_uuid)
  }

  private async fetchSessions () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('sessions/fetchSessions', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
